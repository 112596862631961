import {localStore} from '@s1/extensions';

const DEFAULT_MQ_URL = 'https://www.mapquest.com';
const getMapquestRedirectUrl = () => {
    const store = localStore('directions');
    // @ts-expect-error
    const {redirectUrl} = store.get();

    if (redirectUrl !== undefined) {
        return redirectUrl;
    }

    return DEFAULT_MQ_URL;
};
export default getMapquestRedirectUrl;
