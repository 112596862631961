import React, {FC, useEffect} from 'react';
import {navigate} from 'gatsby';
import {css} from '@emotion/core';
import {useLogging} from '../../../common/hooks/useLogging';
import Redirect from '../util/redirect';
// @ts-expect-error
import {getAppStoreParams} from '../util/extensionMetadata.js';
// @ts-expect-error
import {logDplWithExtensionParams, EVENTS} from '../util/dpl.js';
import {useChannel} from '../../../common/hooks/useChannel';
// @ts-expect-error
import getMapquestRedirectUrl from '../util/directions';

type FollowOnProps = {
    offerLink: string;
    sendNewInstallEvent?: boolean;
};

const SuccessRedirect: FC<FollowOnProps> = ({offerLink, sendNewInstallEvent = true}) => {
    const {InstallEvent} = useLogging();
    const channel = useChannel();

    useEffect(() => {
        const appStoreParams = getAppStoreParams() || {};
        const isPrimaryOffer = channel === '1';

        const loggingPromises = [logDplWithExtensionParams(EVENTS.MapquestExtensionSuccessPageVisit, appStoreParams)];
        if (sendNewInstallEvent) {
            loggingPromises.push(InstallEvent(isPrimaryOffer));
        }

        Promise.all(loggingPromises).finally(() => {
            if (isPrimaryOffer) {
                navigate(offerLink, {state: {channel: '2'}});
            } else {
                Redirect(getMapquestRedirectUrl());
            }
        });
    }, [InstallEvent, channel, offerLink, sendNewInstallEvent]);

    return (
        <div
            css={css`
                padding: 1rem;
            `}
        >
            loading...
        </div>
    );
};

export default SuccessRedirect;
