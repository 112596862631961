import React from 'react';
import SuccessRedirect from '../../components/SuccessRedirect';
// @ts-expect-error
import {getExtensionParamsFromLocalStorage} from '../../util/extensionMetadata';

const SuccessPage = () => {
    const extMeta = getExtensionParamsFromLocalStorage();

    return extMeta ? (
        <SuccessRedirect
            offerLink={`https://search-ace.com/offer/?source=${extMeta.source}&channel=2&campaign=${extMeta.campaign}&adgroup=${extMeta.adgroup}`}
        />
    ) : (
        <SuccessRedirect offerLink="https://search-ace.com/offer/" />
    );
};

export default SuccessPage;
